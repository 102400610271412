document.addEventListener("DOMContentLoaded", function(event) {
	"use strict";

	// Feature slider init
	var featured_slider = new Swiper('.featured-slider', {
	 	slidesPerView: 1,
	 	loop: true,
	 	speed: 640,
		pagination: {
	        el: '.swiper-pagination',
	        clickable: true,
	        renderBullet: function (index, className) {
			    return '<a href="#featured-slider" data-scroll class="' + className + '">' + this.slides[index+1].dataset.title + '</a>';
			}
	    },
	    effect: 'fade',
	    fadeEffect: {
		    crossFade: true
		},
		autoplay: {
    		delay: 8000,
    		disableOnInteraction: false
  		},
	    watchOverflow: true,
		simulateTouch: false,

		autoHeight: true,
	});
});